import React from "react"

import {stepsOrder} from "../components/constants"
import stepDefinitions from "../components/stepDefinitions"
import {
    List,
    ListItem,
    ListItemText,
    Paper,
    Divider,
    makeStyles,
    Collapse,
} from "@material-ui/core"
import {Link} from "gatsby"


const useStyles = makeStyles(theme => ({
    mainHeader: {
        fontWeight: 600
    },
    subheader: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    nestedLevel1: {
        paddingLeft: theme.spacing(4),
    },
    nestedLevel2: {
        paddingLeft: theme.spacing(4),
    },
}))

function getStepNameFromPath(path: string): string | null {
    const regex = /steps\/(\w+)/gm;
    const m = regex.exec(path)
    if (m === null) {
        return null 
    } else {
        return m[1]
    }
}

interface StepsListState {
    activeStepIndex: number | null;
}

interface MainMenuSectionProps {
    path: string
}

const MainMenuSection = ({path}: MainMenuSectionProps) => {

    const classes = useStyles()
    const [open, setOpen] = React.useState(true)
    
    const handleClick = () => {
        setOpen(!open) 
    }
    return (
        <List>
            <ListItem
                button
                onClick={handleClick}
                key="madrich"
            >
                <ListItemText classes={{ primary: classes.mainHeader }}>
                    מדריך
                </ListItemText>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {stepsOrder.map((stepsGroup, index) => (
                    <SubMenuSection
                        key={`stepGroup-${index}`}
                        path={path}
                        stepsGroup={stepsGroup}
                        index={index}
                    />
                ))}
            </Collapse>
        </List>
    )
}


interface SubMenuSectionProps {
    path: string,
    stepsGroup: {title: string, steps: string[]},
    index: number
}

const SubMenuSection = ({ path, stepsGroup }: SubMenuSectionProps) => {

    const classes = useStyles()
    const [open, setOpen] = React.useState(true)

    const handleClick = () => {
        setOpen(!open) 
    }
    return (
        <React.Fragment>
            <ListItem
                button
                className={classes.nestedLevel2}
                onClick={handleClick}
            >
                <ListItemText classes={{ primary: classes.subheader}}>
                    {stepsGroup.title}
                </ListItemText>
            </ListItem>
            <Collapse in={ open } timeout="auto" unmountOnExit>
            {stepsGroup.steps.map((stepName: string) => (
                <ListItem 
                    button
                    key={stepName}
                    component={Link} 
                    to={`/steps/${stepName}`} 
                    selected={getStepNameFromPath(path) === stepName}
                >
                    <ListItemText className={classes.nestedLevel2}>
                        {`${stepDefinitions.he_IL.content[stepName].title}`}
                    </ListItemText>
                </ListItem>             
            ))}
            </Collapse>
        </React.Fragment>
    )
}


interface StepListProps {
    path: string
}

const StepsList = ({ path }: StepListProps) => {
    
    return (
    <Paper elevation={0} square>
        <List style={{ padding: "16px 0" }}>
            <ListItem
                button
                key="main"
                component={Link}
                to="/"
                selected={path === '/'}
            >
                <ListItemText>
                    דף הבית
                </ListItemText>
            </ListItem>
            <Divider />
            <MainMenuSection path={path} />
        </List>
    </Paper>
    )
}

export default StepsList