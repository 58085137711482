import React from "react"
import {
    AppBar,
    Button,
    Toolbar,
    IconButton,
    Box,
    Typography,
    Hidden
  } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Menu as MenuIcon } from "@material-ui/icons"
import {appStoreUrl} from "./constants"
import { Link } from "gatsby"
import { trackOutBoundLink } from "../components/analytics"
const Logo = require("../images/logo.svg")
  
const useStyles = makeStyles(theme => ({
    appbar: {
      backgroundColor: theme.palette.background.paper,
    }
}))

interface KDAppBarProps {
  onMenuButtonClick: () => void,
  hideMenuIconOnLargeScreen: boolean,
}
const KDAppBar = (props: KDAppBarProps) => {
    const classes = useStyles()
    return (
      <AppBar color="inherit" className={classes.appbar}>
        <Toolbar style={{ display: `flex`, alignItems: `center` }}>
          <Hidden lgUp = {props.hideMenuIconOnLargeScreen}>
            <IconButton
              color="inherit"
              aria-label="פתח תפריט"
              onClick={props.onMenuButtonClick}
              edge="start"
            >
              <MenuIcon style={{ width: `2rem`, height: `2rem` }} />
            </IconButton>
          </Hidden>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            style={{ padding: `9px 12px` }}
            >
            <Link to='/'>
              <img
                src={Logo}
                alt="Logo"
                style={{ objectFit: "contain", width: "55px", height: "55px" }}
              />
            </Link>
          </IconButton>
          <Typography variant="h4">
            קיצור דרך
          </Typography>
          <Box style={{ flexGrow: 2 }}></Box>
          <Hidden xsDown>
            <Button
              variant="outlined"
              color="primary"
              href={appStoreUrl}
              style={{ flexShrink: 0 }}
              onClick = {() => trackOutBoundLink(appStoreUrl)}
            >
              נסה עכשיו
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    )
  }

  export default KDAppBar