interface StepDefinitions {
    readonly he_IL: {
        readonly content: {
            readonly [propName: string]: {
                readonly title: string
                readonly description: string
            }
        }
    }
}

const stepDefinitions: StepDefinitions = require("../../../content/db/steps.json")

export default stepDefinitions