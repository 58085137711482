/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { createMuiTheme, ThemeProvider, StylesProvider, jssPreset, responsiveFontSizes, makeStyles } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import Footer from "./footer"
import { Helmet } from 'react-helmet'
import projectTheme from "./theme"
import { CssBaseline, Drawer, Hidden } from '@material-ui/core'
import KDAppBar from "./KDAppBar"
import StepsList from "./stepsList"

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const themeDir = createMuiTheme({...projectTheme, ...{
  direction: 'rtl',
}});
const theme = responsiveFontSizes(themeDir)

function RTL(props: { children: any }) {
  return (
    <StylesProvider jss={jss}>
      <Helmet>
        <body dir={`rtl`} />
      </Helmet>
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </StylesProvider>
  );
}


const rootCommonStyle = {
  display: "grid",
  width: '100vw',
  height: "100vh",
  overflow: 'hidden',
}

const rootHideNavStyle = {
  gridTemplateColumns: "[start] auto [end]",
  gridTemplateRows: "[top] 64px [nav-bar-bottom] auto [bottom]",
  gridTemplateAreas: '"nav-bar" "content"',
}

const rootShowNavStyle = {
  gridTemplateColumns: "[start] 280px [nav-end] auto [end]",
  gridTemplateRows: "[top] 64px [nav-bar-bottom] auto [bottom]",
  gridTemplateAreas: '"nav-bar nav-bar" "nav2 content"',
}

const useStyles = makeStyles(theme => ({
  rootWithNav: {
    ...rootCommonStyle,
    [theme.breakpoints.down("md")]: rootHideNavStyle,
    [theme.breakpoints.up("lg")]: rootShowNavStyle,
  },
  rootWithoutNav: {
    ...rootCommonStyle,
    ...rootHideNavStyle
  }
}), { defaultTheme: theme })

interface LayoutProps {
  children: React.ReactNode,
  showNav: boolean,
  path: string,
}

const Layout = ({ children, showNav, path }: LayoutProps) => {

  const classes = useStyles()
  const [isDrawerOpen, setDrawerOpen] = React.useState(false)

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen)
  }

  return (
    <RTL>
      <CssBaseline />
      <Helmet defer={false}>
        <link href="https://fonts.googleapis.com/css?family=Arimo:400,400i,700,700i|Assistant:400,600,700&display=swap&subset=hebrew" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet" />
      </Helmet>
      <div className={showNav ? classes.rootWithNav : classes.rootWithoutNav} id='main-grid-container'>
        <div style={{ gridArea: 'nav-bar' }}>
          <KDAppBar onMenuButtonClick={toggleDrawer} hideMenuIconOnLargeScreen={showNav} />
        </div>
        {showNav &&
          (<Hidden mdDown>
            <nav
              id="nav2"
              style={{
                gridArea: 'nav2',
                overflowY: 'scroll',
              }} >
              <StepsList path={path} />
            </nav>
          </Hidden>)
        }
        <Drawer
          variant="temporary"
          elevation={16}
          anchor='left'
          open={isDrawerOpen}
          onClose={() => { setDrawerOpen(false) }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            style: { width: '280px', maxWidth: '90vw' }
          }}
        >
          <StepsList path={path} />
        </Drawer>
        <div style={{
          gridArea: 'content',
          placeSelf: 'stretch',
          overflowY: 'scroll',
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }} id="content-root">
          <main>
            {children}
          </main>
            <Footer />
        </div>
      </div>
    </RTL>
  )
}



export default Layout
