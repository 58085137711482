
export const appStoreUrl = 
"https://apps.apple.com/il/app/%D7%A7%D7%99%D7%A6%D7%95%D7%A8-%D7%93%D7%A8%D7%9A-%D7%94%D7%9B%D7%A0%D7%94-%D7%9C%D7%AA%D7%90%D7%95%D7%A8%D7%99%D7%94/id1227012678?mt=8&ct=kitzurweb"
//"https://apps.apple.com/app/apple-store/id1227012678?pt=694778&ct=kitzurweb&mt=8"
export const appStoreReviewURL = appStoreUrl + "#see-all/reviews"

interface StepsGroup {
    title: string
    steps: string[]
}
export const stepsOrder: StepsGroup[] = [
    {
        title: "אישורים",
        steps: [
            "takePhoto",
            "checkVision",
            "getMedicalApproval",
        ]
    },
    {
        title: "תאוריה",
        steps: [
            "learnTheory",
            "passSimulationExam",
            "registerToTheoryExam",
            "getMotStamp",
            "passTheoryExam",
        ]
    },
    {
        title: "נהיגה מעשית",
        steps: [
            "findDrivingInstructor",
            "doDrivingLessons",
        ]
    },
    {
        title: "טסטים",
        steps: [
            "getInstructorApproval",
            "registerToDrivingExam",
            "payForDrivingExam",
            "passDrivingExam",
            "getDrivingExamResults",
            "getTempDrivingLicense",
            "payForDrivingLicense",
            "startDriving",
        ]
    }

    
    
    
    
    
    
    
]