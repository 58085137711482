
declare var window: {
    amplitude?: {
        getInstance: ()=> {
            logEvent: (eventType: string, eventProperties?: object, opt_callback?: (responseCode: number, responseBody: string)=>void) => void
        }
    },
    amplitudeEventTypes?: {
        pageView: string,
        outboundLinkClick: string,
    }
}

export const trackOutBoundLink = (href: string | null = null) => {
    
    if (window.amplitudeEventTypes) {
        const properties: any = {}
        if (href != null) {
            properties["href"] = href
        }
        window.amplitude?.getInstance().logEvent(window.amplitudeEventTypes.outboundLinkClick, properties)
    }
}