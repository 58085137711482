import {
    createMuiTheme
} from '@material-ui/core/styles';
import {
    green,
    amber,
    grey
} from '@material-ui/core/colors'


const headerFontFamily = '"Rubik", "Assistant", "Arial", sans-serif'
const bodyCaptionFontFamily = '"Rubik", "Arimo", "Arial", serif'

const boxShadowParams = `0px 3px 1px -10px rgba(0,0,0,0.2),
                         0px 2px 2px 0px rgba(0,0,0,0.14),
                         0px 1px 5px 0px rgba(0,0,0,0.12)`

const themeParams: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        h1: {
            fontFamily: headerFontFamily
        },
        h2: {
            fontFamily: headerFontFamily,
            fontSize: "1.3rem",
            fontWeight: 400
        },
        h3: {
            fontFamily: headerFontFamily,
            fontWeight: 500
        },
        h4: {
            fontFamily: headerFontFamily,
            fontWeight: 700
        },
        h5: {
            fontFamily: headerFontFamily,
            fontSize: "1.875rem",
            fontWeight: 500
        },
        h6: {
            fontFamily: headerFontFamily,
            fontSize: "2.25rem",
            fontWeight: 500
        },
        subtitle1: {
            fontFamily: bodyCaptionFontFamily
        },
        subtitle2: {
            fontFamily: bodyCaptionFontFamily,
            fontStyle: "italic",
            fontSize: "1.125rem",
            fontWeight: 300
        },
        body1: {
            fontFamily: bodyCaptionFontFamily,
            fontSize: "1.125rem",
            lineHeight: 1.3
        },
        body2: {
            fontFamily: bodyCaptionFontFamily,
        },
        button: {
            fontFamily: bodyCaptionFontFamily,
            fontWeight: 600,
        },
        caption: {
            fontFamily: bodyCaptionFontFamily
        },
        overline: {
            fontFamily: bodyCaptionFontFamily
        }
    },
    overrides: {

        MuiListSubheader: {
            root: {
                fontSize: "1.125rem"
            }
        },
        MuiContainer: {
            root: {

            }
        },
        MuiButton: {
            outlinedPrimary: {
                fontSize: "1rem",
                lineHeight: "1.1875rem",
                padding: "9px 19px 9px 22px",
                border: "2px solid",
                '&:hover': {
                    border: "2px solid",
                    boxShadow: boxShadowParams
                }
            },
            contained: {
                fontSize: "1rem",
                border: "2px solid #FFFFFF",
                backgroundColor: "#FFFFFF",
                color: "#34C759",
                marginTop: "20px",
                marginBottom: "100px",
                width: "119px",
                height: "37px",
                boxShadow: "none",
                '&:hover': {
                    backgroundColor: "#FFFFFF",
                    boxShadow: boxShadowParams
                }
            },
        },
    },
}
themeParams.overrides.MuiContainer.root[`@media (min-width: ${themeParams.breakpoints.values.lg}px)`] = {
    paddingLeft: "24px",
    paddingRight: "24px",
}

themeParams.overrides.MuiContainer.root[`@media (min-width: ${themeParams.breakpoints.values.sm}px) and (max-width: ${themeParams.breakpoints.values.lg-1}px)`] = {
    paddingLeft: "56px",
    paddingRight: "56px",
}

const lightTheme = createMuiTheme({
    ...themeParams,
    ...{
        palette: {
            type: 'light',
            primary: {
                main: "#34C759",
                contrastText: "white"
            },
            secondary: {
                main: "#FF9500"
            },
            background: {
                default: grey[100],
                prominent: "#F7F7F7",
                primaryLight: "#FFFFFF",
                
            },
            text: {
                primary: "#000000",
                secondary: "#032717",
            }
        },
    }
})

const darkTheme = createMuiTheme({
    ...themeParams,
    ...{
        palette: {
            type: 'dark',
            primary: {
                main: green[600],
            },
            secondary: amber,
            background: {
                default: grey[900],
                prominent: grey[700]
            }
        }
    }
})


export default lightTheme