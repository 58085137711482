import React from "react"
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import MuiLink from '@material-ui/core/Link'
import { Divider, Box, makeStyles } from "@material-ui/core"
import {Link} from "gatsby"
import theme from "./theme"

const footerCommonStyle = {
  display: "grid",
  padding: "15px 0 0",
  marginBottom: "120px"
}

const footerDesktop = {
  gridTemplateColumns: "[start] 1fr 350px [end]",
  gridTemplateAreas: '"links contact"',
  alignItems: "center"
}

const footerMobile = {
  gridTemplateAreas: '"links" "contact"',
  justifyContent: "center",
  justifyItems: "center"
}

const useStyles = makeStyles(theme => ({
  footer: {
    ...footerCommonStyle,
    [theme.breakpoints.down("sm")]: footerMobile,
    [theme.breakpoints.up("md")]: footerDesktop,
  },
}))

interface FooterLinkProps {
  href: string,
  children: React.ReactNode,
}

const FooterLink = ({ href, children }: FooterLinkProps) => {
  return (
    <Box color="text.secondary" clone>
      <MuiLink component={Link} to={href} variant="caption" style={{ margin: "0 8px" }}>
        {children}
      </MuiLink>
    </Box>
  )
}

const Footer = () => {

  const classes = useStyles()

  return (
    <footer>
      <Divider />
      <Container>
        <div className={classes.footer}>
          <div style={{ gridArea: "links" }}>
            <FooterLink href="/">דף הבית</FooterLink>{" | "}
            <FooterLink href="/privacypolicy">מדיניות פרטיות</FooterLink>
          </div>
          <div style={{
            gridArea: "contact",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center"
          }}>
            <MuiLink variant="caption" href="mailto:kitzur@ddkmobile.com" 
              style={{ 
                margin: "0 8px",
                color: theme.palette.text.secondary
              }}>
                צור קשר: kitzur@ddkmobile.com
            </MuiLink>
            <Typography
              variant="caption"
              color="textSecondary" 
              style={{ margin: "0 8px"}}>

                כל הזכויות שמורות &copy;

            </Typography>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer